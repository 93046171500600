import {
  doSignature
} from '@/util/api'
import axios from 'axios';

let Base64 = require('js-base64').Base64
/**
 * 对象转formdate
 * @param {object} obj 
 * @returns 
 */
export const changeFormData = (obj = {}) => {
  const formData = new FormData()
  Object.keys(obj).forEach(v => {
    formData.append(v, obj[v])
  });
  return formData
}

/**
 * 随机数长度
 * @param {*} len 
 * @returns 
 */
const random_string = (len) => {
  len = len || 32
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

/**
 * 上传
 */
export const oss = async (file) => {
  const {
    host,
    policy,
    accessid,
    signature,
    callBackUrl,
    dir
  } = await doSignature()
  const key = `${dir}/${new Date().getTime()}_${random_string(10)}signImage.png`
  const updateObj = {
    host,
    key,
    policy,
    OSSAccessKeyId: accessid,
    success_action_status: 200,
    signature,
    callback: callBackUrl,
    headers: {
      Expires: new Date(1000).toUTCString(),
      'x-oss-forbid-overwrite': true
    },
    file
  }
  const {
    size,
    object,
    mimeType
  } = await axios.post(host, changeFormData(updateObj), {
    headers: {
      ...updateObj.headers,
      accesstoken: sessionStorage.accesstoken
    }
  })
  return Promise.resolve({
    size,
    object,
    mimeType,
    host
  })
}

/**
 * 预览地址
 */
export const ossUrl = (res) => {
  const url = encodeURIComponent(Base64.encode(res))
  if (process.env.NODE_ENV === 'development') {
    location.href = (`https://emtest.tortoisecloud.com:48081/preview/onlinePreview?url=${url}`)
  } else {
    location.href = (`/preview/onlinePreview?url=${url}`)
  }
}

/**
 * 文件下载
 */
export const downloadFile = (res, type, filename) => {
  // 创建blob对象，将二进制数据封装为BLOB对象
  const blob = new Blob([res], {
    // 如何后端没返回下载文件类型，则需要手动设置：type: 'application/pdf;chartset=UTF-8' 表示下载文档为pdf，如果是word则设置为msword，excel为excel
    type: type
  })
  const a = document.createElement('a')
  // 兼容webkix浏览器，处理webkit浏览器中herf自动添加blob前缀，默认在浏览器打开而不是下载
  const URL = window.URL || window.webkitURL
  // 根据blob对象创建URL 对象，生成本地URL
  const herf = URL.createObjectURL(blob)
  // 下载链接
  a.href = herf
  // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  // 在内存中移除URL 对象
  window.URL.revokeObjectURL(herf)
}